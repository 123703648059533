(function () {
  'use strict';

  /**
          Útil per posar tds en un ng-repeat d'un tr



   * @ngdoc directive
   * @name shared.directive:replaceMe
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="shared">
       <file name="index.html">
        <replace-me></replace-me>
       </file>
     </example>
   *
   */
  angular
    .module('itecTcqihome.shared.tables')
    .directive('replaceMe', replaceMe);

  function replaceMe($compile, $http, $templateCache) {
    return {
            restrict: 'A',
            scope: {
               row: '=',
               controlador: '='
            },
            link: function (scope, element, attrs) {
                function getTemplate(template) {
                    $http.get(template, {cache: $templateCache}).success(function (templateContent) {
                        element.replaceWith($compile(templateContent)(scope));
                    });
                }

                scope.$watch(attrs.template, function () {
                    if (attrs.template) {
                        getTemplate(attrs.template);
                    }
                });
            }
        };
      }
}());
