(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.shared.estat
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.headerSubmenu', []);
}());
