(function () {
  'use strict';

  /* @ngdoc object
   * @name commonControls.utils.tcq-obra-form-upload
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.tcqBc3FormUpload', [
      'ngFileUpload'
    ]);
}());
