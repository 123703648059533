(function () {
  'use strict';

  angular
    .module('itecTcqihome.api')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('api', {
        url: '/api',
        templateUrl: 'api/views/api.tpl.html',
        controller: 'ApiCtrl',
        controllerAs: 'api'
      });
  }
}());
