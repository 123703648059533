(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.constant:headerMenuIconsPosition
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome')
    .value('headerMenuIconsPosition', 'up'); // Opciones: 'up', 'down', 'left' o 'right'.
}());
