(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.api.locale.factory:LocaleFactory
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.locale')
    .factory('LocaleFactory', LocaleFactory);

  function LocaleFactory(DataFactory, ApiLayoutConstant) {
    var api = ApiLayoutConstant.SERVER_PATH_MANTENIMENT + 'locales';

    var base = {
      getAll: function(){
        var url = api;
        return DataFactory.get(url);
      },
      getCurrent: function(){
        var url = api + '/session';
        return DataFactory.get(url);
      },
      updateCurrent: function(obj){
        var url = api + '/session';
        return DataFactory.update(url,obj);
      }
    };
    return base;
  }
}());
