(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.shared.submain
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.submain', [
    ]);
}());
