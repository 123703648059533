<div class="mdl-grid mdl-grid--no-spacing">

  <itec-form-file-upload
    name="form"
    class="mdl-cell mdl-cell--12-col"
    button-class="itec-float-right"
    files="vm.files"
    err-files="vm.errFiles"
    funcio-seleccionar="vm.selectFile()"
    select-files-text="{{selectFilesText}}"
    max-size="{{vm.maxSize}}"
    pattern="['.bc3', '.zip']"
    icon-left="vm.iconLeft">
  </itec-form-file-upload>

  <!--<div class="mdl-cell mdl-cell--12-col" ng-show="vm.objFile.status == 'uploading' || vm.objFile.status == 'done'">
    {{vm.objFile.file.name}}
    <md-button ng-show="vm.objFile.progress==100" class="md-icon-button">
      <md-icon>done</md-icon>
    </md-button>
    <span flex></span>
    <md-progress-linear md-mode="determinate" value="{{vm.objFile.progress}}">
    </md-progress-linear>
  </div>-->

  <itec-form-file-upload-progress ng-if="vm.objFile !== undefined" file="vm.objFile"></itec-form-file-upload-progress>

  <itec-form-file-upload-errors
    err-files="vm.errFiles"
    max-size="{{vm.maxSize}}"
    max-size-exceeded-text={{maxSizeExceededText}}>
  </itec-form-file-upload-errors>

  <itec-error-block 
    errors="vm.errors['']" 
    class="validation-messages mdl-cell mdl-cell--12-col">
  </itec-error-block>

</div>