
         <div class="mdl-cell--1-col tcqi--ellipsis tcqihome--expand-subsiquillos"
                              ng-class="{'tcqi--text-align-right': obra.isSubobra}">

            <i ng-if="obra.isSisquilloCompartit" class="material-icons"
             ng-class="{'tcqihome--subobra-ident': obra.isSubobra, 'tcqihome--subobres':obra.isSubobra}">person</i>

             <div ng-if="ctrl.tesubsisquillos(obra)" class="tcqi--text-align-right tcqihome--obres-table-tbody-td-subobres">
                 <a ng-if="!obra.isSubObraExpand" ng-click="ctrl.setSubObraExpand(true,obra);">
                   <i class="icon-gui-arrow-closed tcqi--text-align-right"></i>
                 </a>
                 <a  ng-if="obra.isSubObraExpand" ng-click="ctrl.setSubObraExpand(false,obra);">
                   <i class="icon-gui-arrow-open tcqi--text-align-right"></i>
                 </a>
              </div>
        </div>

        <div class="mdl-cell--1-col bold tcqihome--obres-table-tbody-td-codigo tcqi--ellipsis"
            title="{{::obra.codi}}">
          <span  ng-class="{'tcqihome--subobra-ident': obra.isSubobra}" >{{obra.codi}}</span>
        </div>
        <div class="mdl-cell--3-col tcqi--ellipsis" title="{{::obra.descripcio}}"
            >
          <span ng-class="{'tcqihome--subobra-ident': obra.isSubobra}">{{obra.descripcio}}</span>
        </div>

          <div class="mdl-cell--1-col tcqihome--obres-table-tbody-td-fecha tcqi--padding-left-30"
             >
            <span ng-if="obra.dataCreacio !== 'DEFAULT_NOW'">
              {{obra.dataCreacio | date: 'itecDate'}}
            </span>

            <span ng-if="obra.dataCreacio === 'DEFAULT_NOW'">
              {{obra.dataCreacio | translate}}
            </span>
          </div>

          
          <div class="mdl-cell--4-col tcqihome--obres-table-tbody-td-acciones">
            <div class="tcqi--padding-left-15 tcqi--center-text">
               <div style="width:12.5%">
                <button ng-if="!obra.isSubobra"
                    class="mdl-button mdl-js-button mdl-button--icon "
                    title="{{::'DEFAULT_FASE_PRODUCTE' | translate}}">
                    <i class="icon-gma-fase-fabricacio color-fabricacio "></i>
                </button></br>
                <span>
                  {{obra.emisions.fabricacio | number : 2}}
                </span>
               </div>
                <div style="width:12.5%">
                  <div ng-if="obra.emisions.compensadesFabricacio > 0">
                   <button ng-if="!obra.isSubobra"
                      class="mdl-button mdl-js-button mdl-button--icon">
                      <i class="icon-IconeLogoClimatetrade color-climatetrade"></i>
                  </button></br>
                 {{obra.emisions.compensadesFabricacio | number : 2}}
                    </div>
               </div>
               <div style="width:12.5%">
                <button ng-if="!obra.isSubobra"
                    class="mdl-button mdl-js-button mdl-button--icon"
                    title="{{::'DEFAULT_FASE_CONSTRUCCIO' | translate}}">
                    <i class="icon-gma-fase-construccio color-construccio"></i>
                </button></br>
                 <span>
                  {{obra.emisions.construccio | number : 2}}
                </span>
              </div>
                 <div style="width:12.5%">
                   <div ng-if="obra.emisions.compensadesConstruccio > 0">
                 <button ng-if="!obra.isSubobra"
                      class="mdl-button mdl-js-button mdl-button--icon">
                      <i class="icon-IconeLogoClimatetrade color-climatetrade"></i>
                  </button></br>
                 {{obra.emisions.compensadesConstruccio | number : 2}}
                 </div>
                </div>
                   <div style="width:12.5%">
                  <button ng-if="!obra.isSubobra"
                      class="mdl-button mdl-js-button mdl-button--icon"
                      title="{{::'DEFAULT_FASE_US' | translate}}">
                      <i class="icon-gma-fase-us color-us"></i>
                  </button></br>
                  <span>
                  {{obra.emisions.us | number : 2}}
                  </span>
              </div>
                 <div style="width:12.5%">
                  <div ng-if="obra.emisions.compensadesUs > 0">
                   <button ng-if="!obra.isSubobra"
                      class="mdl-button mdl-js-button mdl-button--icon">
                         <i class="icon-IconeLogoClimatetrade color-climatetrade"></i>
                  </button></br>
                 {{obra.emisions.compensadesUs | number : 2}}
                    </div>
                  </div>
              <div style="width:12.5%">
                <button ng-if="!obra.isSubobra"
                    class="mdl-button mdl-js-button mdl-button--icon"
                    title="{{::'DEFAULT_FASE_FINAL_DE_VIDA' | translate}}">
                    <i class="icon-gma-fase-final-de-vida color-final"></i>
                </button></br>
                <span>
                 {{obra.emisions.finalDeVida | number : 2}}
                </span>
              </div>
                 <div style="width:12.5%">
                <div ng-if="obra.emisions.compensadesFinalDeVida > 0">
                <button ng-if="!obra.isSubobra"
                      class="mdl-button mdl-js-button mdl-button--icon">
                      <i class="icon-IconeLogoClimatetrade color-climatetrade"></i>
                  </button></br>
                 {{obra.emisions.compensadesFinalDeVida | number : 2}}
               </div>
              </div>
            </div>
          </div>


          <div class="mdl-cell--2-col tcqihome--obres-table-tbody-td-apps">
            <div class="tcqi--padding-left-15 tcqi--center-text">
               
              <!-- mediambient -->
              <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-gma-color"
                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_MEDIAMBIENT,
                      $root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces,false)"
                      ng-class="{'tcqi-gma-color-permisos-nodades':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces && !obra.temediambient,
                      'tcqi-gma-color-no-permisos-dades':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces && obra.temediambient,
                      'tcqihome--hidden':obra.isSubobra}"
                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces">
                <i class="icon-tcqi-icona-gma" title="{{ctrl.titlemodul(obra.temediambient,$root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces)}}"></i>
              </button>


            </div>
          </div>
