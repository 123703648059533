<!-- <img src="vendor/itec-icons/images/tcqi-icona-ban.png"/> -->
<div id="tcqi-content" class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing">

  <!-- Botones -->
  <div id="tcqihome--obres-buttons" class="mdl-cell--12-col tcqi--margin-top-30">
    <div class="tcqihome--obres-title">
      <i class="material-icons">folder</i>
      <span class="bold">{{::'OBRES_TOTES_OBRES' | translate}}</span>
    </div>

    <button class="tcqi--margin-left-30 tcqi--margin-right-15 itec-float-right mdl-button mdl-js-button
        mdl-button--icon mdl-button--colored" ng-click="ctrl.listMode = !ctrl.listMode"
        ng-if="(ctrl.obres.length > 0 || ctrl.obrescompartides.length>0 || ctrl.searchMode)">

      <i ng-if="ctrl.listMode" class="icon-th-large" style="font-size: large;"
         title="{{::'DEFAULT_ACCIONS_VISUALITZAR_ICONES' | translate}}"
         aria-label="{{::'DEFAULT_ACCIONS_VISUALITZAR_ICONES' | translate}}">

      </i>
      <i ng-if="!ctrl.listMode" class="material-icons"
         title="{{::'DEFAULT_ACCIONS_VISUALITZAR_LLISTA' | translate}}"
         aria-label="{{::'DEFAULT_ACCIONS_VISUALITZAR_LLISTA' | translate}}">
        format_list_bulleted
      </i>
    </button>

    <tcq-bim-form-upload
      ng-if="$root.permisos.SISQUILLO_IMPORTAR_BIM.read || true"
      is-new = "true"
      dades-generals="ctrl.dadesGeneralsData"
      select-files-text="DEFAULT_IMPORTAR_BIM"
      save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"
      max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"
      max-size="{{ctrl.maxObraSize}}"
      show-only-icon="false"
      on-finish-function="ctrl.viewDades"
      server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_LOADER}}"
      server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"
      class="itec-float-right"
      icon-left-content="cloud_download"
      title="{{'BIM_ARXIUS_ADD_TITLE' | translate}}"
      show-toolbar-dialog="true">
    </tcq-bim-form-upload>

    <tcq-bc3-form-upload
      ng-if="$root.permisos.SISQUILLO_IMPORTAR_FIE.read"
      select-files-text="DEFAULT_IMPORTAR_FIE"
      save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"
      max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"
      max-size="{{ctrl.maxObraSize}}"
      show-only-icon="false"
      on-finish-function="ctrl.viewDades"
      icon-left="ctrl.iconLeft"
      server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_LOADER}}"
      server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"
      class="itec-float-right">
    </tcq-bc3-form-upload>

    <tcq-obra-form-upload
      ng-if="$root.permisos.SISQUILLO_IMPORTAR_TCQI.read"
      select-files-text="DEFAULT_IMPORTAR_TCQI"
      save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"
      max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"
      max-size="{{ctrl.maxObraSize}}"
      show-only-icon="false"
      on-finish-function="ctrl.viewDades"
      server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_LOADER}}"
      server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"
      class="itec-float-right">
    </tcq-obra-form-upload>

    <itec-button ng-if="$root.permisos.SISQUILLO.add" class="itec-float-right tcqi--margin-left-20"
        label="DEFAULT_NOU" ng-click-function="ctrl.addSisquillo()" icon-left="ctrl.addButton">
    </itec-button>

    <form ng-if="$root.permisos.SISQUILLO.read"
        class="itec-float-right">
            <div ng-show="ctrl.expanded">
                <label class="itec-float-left mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
                 ng-click="ctrl.closeSearchSisquillos()">
            <i class="material-icons" title="{{::'DEFAULT_TANCAR' | translate}}"
                aria-label="{{::'DEFAULT_TANCAR' | translate}}">closeround</i>
          </label>

          <label class="itec-float-left mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
                 ng-click="ctrl.searchSisquillos()">
            <i class="material-icons" title="{{::'DEFAULT_CERCAR' | translate}}"
                aria-label="{{::'DEFAULT_CERCAR' | translate}}">search</i>
          </label>

          <div class="tcqihome-search itec-float-left mdl-textfield mdl-js-textfield">
              <input class="mdl-textfield__input" type="text" id="search-expandable" ng-model="ctrl.searchText"
              ng-keydown="ctrl.escucharEnterSearch($event)" />
              <label class="mdl-textfield__label" for="search-expandable" ng-hide="ctrl.searchText">
                {{::'DEFAULT_CERCA_CODI_O_TITOL' | translate}}
              </label>
          </div>
        </div>
        <div ng-show="!ctrl.expanded">
           <label class="itec-float-left mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
                 ng-click="ctrl.searchSisquillos()">
            <i class="material-icons" title="{{::'DEFAULT_CERCAR' | translate}}"
                aria-label="{{::'DEFAULT_CERCAR' | translate}}">search</i>
          </label>
        </div>
    </form>
  </div>





  <!-- Módulos "TCQi" -->
  <div ng-if="ctrl.obres.length === 0 && ctrl.obrescompartides.length===0 && !ctrl.searchMode" id="tcqihome--obres-no-items">
    <span>{{::'DEFAULT_NO_ITEM' | translate}}</span>
    <div class="clear"></div>

    <div class="tcqihome--obres-title">
      <i class="icon-icono-grups-blanc"></i>
      <span class="bold">{{::'DEFAULT_MODULS' | translate}}</span>
    </div>
    <div class="tcqi-table mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqi--margin-top-20">
      <div ng-repeat="tcqiApp in ctrl.tcqiApps track by $index" class="tcqihome--obres-apps mdl-cell--2-col">
        <div ng-class="{'tcqiApps--app-disabled': !$root.acces[tcqiApp.id].acces}"
             ng-click="ctrl.openObra(undefined, tcqiApp.app, $root.acces[tcqiApp.id].acces)">
          <a>
            <img ng-src="{{tcqiApp.image}}"><br>
            <span class="itec-float-left tcqi--margin-top-10">
              {{::tcqiApp.title | translate | uppercase}}
            </span>
          </a>
        </div>

        <div class="clear"></div>
        <span>{{::tcqiApp.descr}}</span>
      </div>
    </div>
  </div>




  <!-- Obras propias del usuario en sesión -->
  <div ng-if="ctrl.obres.length === 0 && $root.permisos.SISQUILLO.read && !ctrl.searchMode" ng-show="ctrl.showObras">
    {{::'OBRES_EMPTY_LIST' | translate}}
  </div>

  <div ng-if="(ctrl.obres.length > 0 && $root.permisos.SISQUILLO.read) || ctrl.searchMode" ng-show="ctrl.showObras"
      id="tcqihome--obres-list" class="tcqi-table mdl-cell--12-col">

    <!-- Cabecera -->
    <div ng-if="ctrl.listMode" class="tcqi--table-header mdl-grid mdl-grid--no-spacing tcqi--padding-bottom-5">

      <div></div>

      <div class="mdl-cell--1-col">
        <a ng-click="ctrl.sortType = 'codi'; ctrl.sortReverse = !ctrl.sortReverse" class="sortable">
          <span>{{::'DEFAULT_CODI' | translate}}</span>

          <i ng-show="ctrl.sortType === 'codi' && !ctrl.sortReverse" class="icon-caret-down"></i>
          <i ng-show="ctrl.sortType !== 'codi'" class="icon-caret-down tcqi-two-icon-carets"></i>

          <i ng-show="ctrl.sortType === 'codi' && ctrl.sortReverse" class="icon-caret-up"></i>
          <i ng-show="ctrl.sortType !== 'codi'" class="icon-caret-up tcqi-two-icon-carets"></i>
        </a>
      </div>

      <div class="mdl-cell--3-col">
        <a ng-click="ctrl.sortType = 'descripcio'; ctrl.sortReverse = !ctrl.sortReverse" class="sortable">
          <span>{{::'DEFAULT_TITOL' | translate}}</span>

          <i ng-show="ctrl.sortType === 'descripcio' && !ctrl.sortReverse" class="icon-caret-down"></i>
          <i ng-show="ctrl.sortType !== 'descripcio'" class="icon-caret-down tcqi-two-icon-carets"></i>

          <i ng-show="ctrl.sortType === 'descripcio' && ctrl.sortReverse" class="icon-caret-up"></i>
          <i ng-show="ctrl.sortType !== 'descripcio'" class="icon-caret-up tcqi-two-icon-carets"></i>
        </a>
      </div>

      <div class="mdl-cell--1-col tcqi--center-text">
        <a ng-click="ctrl.sortType = 'dataCreacio'; ctrl.sortReverse = !ctrl.sortReverse" class="sortable">
          {{::'DEFAULT_DATA' | translate}}

          <span ng-show="ctrl.sortType === 'dataCreacio' && !ctrl.sortReverse" class="icon-caret-down"></span>
          <span ng-show="ctrl.sortType !== 'dataCreacio'" class="icon-caret-down tcqi-two-icon-carets"></span>

          <span ng-show="ctrl.sortType === 'dataCreacio' && ctrl.sortReverse" class="icon-caret-up"></span>
          <span ng-show="ctrl.sortType !== 'dataCreacio'" class="icon-caret-up tcqi-two-icon-carets"></span>
        </a>
      </div>

      <div class="mdl-cell--4-col tcqi--center-text"><span>{{::'DEFAULT_APLICACIONS' | translate}}</span></div>
      <div class="mdl-cell--2-col tcqi--center-text"><span>{{::'DEFAULT_ACCIONS' | translate}}</span></div>
    </div>

    <!-- Ítems. Formato estándar. -->
    <div ng-if="ctrl.listMode" id="tcqihome--obres-list-tbody"
         class="tcqi--table-tbody tcqi--margin-top-10 tcqi--with-scroll"
         ng-class="{'tcqi--not-recalculate-height': ctrl.obrescompartides === null}"
         itec-infinite-scroll="(ctrl.searchText === '' ? ctrl.loadSisquillos(true) : ctrl.loadSearchSisquillos(true))" threshold="100" can-load="ctrl.canLoad">


      <div ng-repeat="obra in ctrl.obres |
                      orderBy:ctrl.sortType:ctrl.sortReverse track by $index"
           class="mdl-grid mdl-grid--no-spacing " ng-class="{'tcqihome--subobres-expand': obra.isSubObraExpand}"
>
            <div ng-include="'obres/obra.tpl.html'" class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqihome--subobres "></div>
            <div ng-repeat="obra in obra.subSisquillos"
                 class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqihome--subobres "  ng-show="obra.visible"
                  ng-include="'obres/obra.tpl.html'">
            </div>
      </div>
    </div>

    <!-- Ítems. Formato cuadrícula -->
    <div ng-if="!ctrl.listMode" class="mdl-grid mdl-grid--no-spacing">
      <div ng-repeat="obra in ctrl.obres track by $index"
          class="mdl-cell mdl-cell--3-col tcqihome-gallery-container">

        <div class="tcqihome-gallery-element">
          <div class="tcqihome-gallery-imatge">
            <span class="tcqihome-gallery-imatge-helper"></span><img ng-if="obra.imatge"
              data-ng-src="data:image/png;base64,{{obra.imatge.data}}" ng-click="ctrl.viewDades(obra.id)">

            <img ng-if="!obra.imatge" class="ng-thumb"
                 ng-src="{{$root.prefixItecGuicomponents}}images/common-controls/views/images/icoimg.gif"
                 ng-click="ctrl.viewDades(obra.id)">
          </div>

          <div><span>{{obra.descripcio}}</span></div>
        </div>
      </div>
    </div>
  </div>



  <!-- Obras compartidas -->
  <div ng-if="(ctrl.entitats.length > 0) && $root.permisos.SISQUILLO.read"
    ng-show="ctrl.showObras"
      id="tcqihome--obrescompartides" class="tcqi-table mdl-cell--12-col">


    <!-- Ítems. Formato estándar. -->
    <div class="tcqihome--obres-title tcqi--margin-left-30 tcqi--margin-bottom-40">

      <i class="material-icons itec-float-left">folder</i>
      <span class="bold">{{::'OBRES_COMPARTIDES' | translate}}</span>


      <!-- Entidades -->
      <div ng-if="ctrl.entitats.length > 0 && $root.permisos.SISQUILLO.read" id="tcqihome--entitats"
          class="itec-float-right tcqi--margin-right-30">
        <form>
          <itec-select
              name="entitat"
              label="{{::'DEFAULT_ENTITAT' | translate}}"
              list="ctrl.entitats"
              property-label="nom"
              ng-model="ctrl.entitatSelected"
              property-id="id"
              ng-change-function="ctrl.showObresByEntitat(true)"
              max-chars="25">
          </itec-select>
        </form>
      </div>
    </div>


    <div id="tcqihome--obrescompartides-list">

      <!-- Cabecera -->
      <div ng-if="(ctrl.listMode && ctrl.obres.length > 0) || ctrl.searchMode" class="tcqi--table-header mdl-grid mdl-grid--no-spacing
          tcqi--margin-bottom-10 tcqi--padding-bottom-5 tcqi--all-width">

        <div class="tcqi--center-text"></div>

        <div class="mdl-cell--1-col">
          <a ng-click="ctrl.sortTypeObrasCompartidas = 'codi';
                ctrl.sortReverseObrasCompartidas = !ctrl.sortReverseObrasCompartidas" class="sortable">

            <span>{{::'DEFAULT_CODI' | translate}}</span>

            <i ng-show="ctrl.sortTypeObrasCompartidas === 'codi' && !ctrl.sortReverseObrasCompartidas"
                class="icon-caret-down"></i>
            <i ng-show="ctrl.sortTypeObrasCompartidas !== 'codi'" class="icon-caret-down tcqi-two-icon-carets"></i>

            <i ng-show="ctrl.sortTypeObrasCompartidas === 'codi' && ctrl.sortReverseObrasCompartidas"
                class="icon-caret-up"></i>
            <i ng-show="ctrl.sortTypeObrasCompartidas !== 'codi'" class="icon-caret-up tcqi-two-icon-carets"></i>
          </a>
        </div>

        <div class="mdl-cell--3-col">
          <a ng-click="ctrl.sortTypeObrasCompartidas = 'descripcio';
              ctrl.sortReverseObrasCompartidas = !ctrl.sortReverseObrasCompartidas" class="sortable">

            <span>{{::'DEFAULT_TITOL' | translate}}</span>

            <i ng-show="ctrl.sortTypeObrasCompartidas === 'descripcio' && !ctrl.sortReverseObrasCompartidas"
                class="icon-caret-down"></i>
            <i ng-show="ctrl.sortTypeObrasCompartidas !== 'descripcio'"
                class="icon-caret-down tcqi-two-icon-carets"></i>

            <i ng-show="ctrl.sortTypeObrasCompartidas === 'descripcio' && ctrl.sortReverseObrasCompartidas"
                class="icon-caret-up"></i>
            <i ng-show="ctrl.sortTypeObrasCompartidas !== 'descripcio'"
                class="icon-caret-up tcqi-two-icon-carets"></i>
          </a>
        </div>

        <div class="mdl-cell--1-col tcqi--center-text">
          <a ng-click="ctrl.sortTypeObrasCompartidas = 'dataCreacio';
                ctrl.sortReverseObrasCompartidas = !ctrl.sortReverseObrasCompartidas" class="sortable">
            {{::'DEFAULT_DATA' | translate}}

            <span ng-show="ctrl.sortTypeObrasCompartidas === 'dataCreacio' && !ctrl.sortReverseObrasCompartidas"
                class="icon-caret-down"></span>
            <span ng-show="ctrl.sortTypeObrasCompartidas !== 'dataCreacio'"
                class="icon-caret-down tcqi-two-icon-carets"></span>

            <span ng-show="ctrl.sortTypeObrasCompartidas === 'dataCreacio' && ctrl.sortReverseObrasCompartidas"
                class="icon-caret-up"></span>
            <span ng-show="ctrl.sortTypeObrasCompartidas !== 'dataCreacio'"
                class="icon-caret-up tcqi-two-icon-carets"></span>
          </a>
        </div>

        <div class="mdl-cell--4-col tcqi--center-text"><span>{{::'DEFAULT_APLICACIONS' | translate}}</span></div>
        <div class="mdl-cell--2-col tcqi--center-text"><span>{{::'DEFAULT_ACCIONS' | translate}}</span></div>
      </div>

      <!-- Ítems -->
      <div class="tcqi--table-tbody">
        <div ng-repeat="obra in ctrl.obrescompartides | 
                        orderBy:ctrl.sortTypeObrasCompartidas:ctrl.sortReverseObrasCompartidas track by $index"
             class="mdl-grid mdl-grid--no-spacing" itec-on-finish-render="ngRepeatFinished">
          <div class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqihome--subobres ">
              <div class="tcqi--center-text">
                <i ng-if="obra.isSisquilloCompartit" class="material-icons">person</i>
              </div>

              <div class="mdl-cell--1-col bold tcqihome--obres-table-tbody-td-codigo tcqi--ellipsis"
                  ng-click="ctrl.viewDadesCompartides(obra.id)" title="{{::obra.codi}}">
                <span>{{obra.codi}}</span>
              </div>

              <div class="mdl-cell--3-col tcqi--ellipsis" title="{{::obra.descripcio}}"
                  ng-click="ctrl.viewDadesCompartides(obra.id)">
                <span>{{obra.descripcio}}</span>
              </div>

              <div class="mdl-cell--1-col tcqihome--obres-table-tbody-td-fecha tcqi--padding-left-20"
                  ng-click="ctrl.viewDadesCompartides(obra.id,obra.dadesgenerals)">
                <span ng-if="obra.dataCreacio !== 'DEFAULT_NOW'" class="tcqi--padding-left-15">
                  {{obra.dataCreacio | date: 'itecDate'}}
                </span>
                <span ng-if="obra.dataCreacio === 'DEFAULT_NOW'" class="tcqi--padding-left-15">
                  {{obra.dataCreacio | translate}}
                </span>
              </div>

              <div class="mdl-cell--4-col tcqihome--obres-table-tbody-td-apps">
                <div class="tcqi--padding-left-15 tcqi--center-text">

                <!-- banc -->
                  <button ng-if ="false" class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-ban-color"
                          ng-class="{
                          'tcqi-ban-color-permisos-nodades':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_BANC) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_BANC}}].acces && !obra.tebanc,
                          'tcqi-ban-color-no-permisos-dades':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_BANC) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_BANC}}].acces)
                           && obra.tebanc,'tcqihome--hidden':obra.idpare!==null}"
                          ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_BANC,
                          ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_BANC)
                          && $root.acces[ctrl.ApiLayoutConstant.APP_ID_BANC].acces,true)"
                          ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_BANC)
                                      || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_BANC}}].acces">
                    <i class="icon-tcqi-icona-ban" title="{{ctrl.titlemodul(obra.tebanc,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_BANC) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_BANC].acces)}}"></i>
                  </button>

                  <!-- pressupost -->
                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-pre-color"
                          ng-class="{'tcqi-pre-color-permisos-nodades':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST)
                          && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST}}].acces && !obra.tepressupost,
                          'tcqi-pre-color-no-permisos-dades':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST)
                          || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST}}].acces) && obra.tepressupost}"
                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_PRESSUPOST,
                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST)
                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST].acces,true)"
                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST)
                                    || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST].acces">
                  <i class="icon-tcqi-icona-pre" title="{{ctrl.titlemodul(obra.tepressupost,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST].acces)}}"></i>
                </button>

                <!-- certificacio -->
                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-seg-color"
                ng-class="{
                'tcqi-seg-color-permisos-nodades':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC}}].acces && !obra.teSeguiment,
                'tcqi-seg-color-no-permisos-dades':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC}}].acces)
                 && obra.teSeguiment,
                 'tcqihome--hidden': obra.idpare!==null && obra.tipusSubSisquillo===2}"
                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_SEGUIMENTECONOMIC,
                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC)
                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC].acces,true)"
                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC)
                        || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC].acces">
                  <i class="icon-tcqi-icona-cer-1" title="{{ctrl.titlemodul(obra.teSeguiment,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC].acces)}}"></i>
                </button>

                <!-- planificacio -->
                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-pla-color"
                        ng-class="{
                        'tcqi-pla-color-permisos-nodades':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO}}].acces && !obra.tepladetreballs,
                        'tcqi-pla-color-no-permisos-dades':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO}}].acces)
                         && obra.tepladetreballs,'tcqihome--hidden':obra.idpare!==null}"
                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_PLANIFICACIO,
                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO)
                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO].acces,true)"
                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO)
                        || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO].acces">
                  <i class="icon-tcqi-icona-pla"  title="{{ctrl.titlemodul(obra.tepladetreballs,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO].acces)}}"></i>
                </button>

                <!-- qualitat -->
                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-gcq-color"
                        ng-class="{
                        'tcqi-gcq-color-permisos-nodades':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_QUALITAT) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_QUALITAT}}].acces && !obra.tequalitat,
                        'tcqi-gcq-color-no-permisos-dades':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_QUALITAT) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_QUALITAT}}].acces)
                        && obra.tequalitat,'tcqihome--hidden':obra.idpare!==null}"
                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_QUALITAT,
                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_QUALITAT)
                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_QUALITAT].acces,true)"
                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_QUALITAT)
                        || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_QUALITAT].acces">
                  <i class="icon-tcqi-icona-gcq" title="{{ctrl.titlemodul(obra.tequalitat,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_QUALITAT) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_QUALITAT].acces)}}"></i>
                </button>

                <!-- mediambient -->
                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-gma-color"
                        ng-class="{
                        'tcqi-gma-color-permisos-nodades':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces && !obra.temediambient,
                        'tcqi-gma-color-no-permisos-dades':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces)
                        && obra.temediambient,'tcqihome--hidden':obra.idpare!==null}"
                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_MEDIAMBIENT,
                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT)
                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces,true)"
                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT)
                        || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces">
                  <i class="icon-tcqi-icona-gma" title="{{ctrl.titlemodul(obra.temediambient,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces)}}"></i>
                </button>

                <!-- seguretat i salut -->
                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-ess-color"
                        ng-class="{
                        'tcqi-ess-color-permisos-nodades':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT}}].acces && !obra.teseguretatisalut,
                        'tcqi-ess-color-no-permisos-dades':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT}}].acces)
                         && obra.teseguretatisalut,'tcqihome--hidden':obra.idpare!==null}"
                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_SEGURETATISALUT,
                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT)
                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT].acces,true)"
                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT)
                         || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT].acces">
                  <i class="icon-tcqi-icona-ess" title="{{ctrl.titlemodul(obra.teseguretatisalut,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT].acces)}}"></i>
                </button>

                <!-- control de costos -->
                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-cdc-color"
                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_CONTROLCOSTOS,
                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS)
                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS].acces,true)"
                        ng-class="{
                        'tcqi-cdc-color-permisos-nodades':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS}}].acces && !obra.tecontrolcostos,
                        'tcqi-cdc-color-no-permisos-dades':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS}}].acces)
                         && obra.tecontrolcostos,'tcqihome--hidden':obra.idpare!==null}"
                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS)
                        || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS].acces">
                  <i class="icon-tcqi-icona-gcc"  title="{{ctrl.titlemodul(obra.tecontrolcostos,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS].acces)}}"></i>
                </button>

                <!-- estimacio de costos -->
                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-edc-color"
                        ng-class="{
                        'tcqi-edc-color-permisos-nodades':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS}}].acces && !obra.teestimaciocostos,
                        'tcqi-edc-color-no-permisos-dades':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS}}].acces)
                         && obra.teestimaciocostos,'tcqihome--hidden':obra.idpare!==null}"
                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_ESTIMACIOCOSTOS,
                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS)
                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS].acces,true)"
                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS)
                        || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS].acces">
                  <i class="icon-tcqi-icona-edc" title="{{ctrl.titlemodul(obra.teestimaciocostos,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS].acces)}}"></i>
                </button>
                </div>
              </div>

              <div class="mdl-cell--2-col tcqihome--obres-table-tbody-td-acciones">
                <div class="tcqi--padding-left-15 tcqi--center-text">
                  <button
                      ng-disabled="!obra.dadesgenerals || !$root.permisos.SISQUILLO.edit"
                      class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
                      title="{{::'DEFAULT_DADES_GENERALS' | translate}}"
                      ng-click="ctrl.viewDadesCompartides(obra.id,obra.dadesgenerals)">
                    <i class="material-icons">description</i>
                  </button>

                  <button ng-disabled="!obra.eliminarobra || !$root.permisos.SISQUILLO.delete"
                          class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
                          title="{{::'DEFAULT_ESBORRAR_OBRA' | translate}}"
                          itec-dialog-confirm
                          itec-dialog-confirm-title="OBRES_DELETE_TITLE"
                          itec-dialog-confirm-text-content="OBRES_DELETE_CONTENT"
                          itec-dialog-confirm-text-ok="DEFAULT_ESBORRAR"
                          itec-dialog-confirm-text-cancel="DEFAULT_CANCELAR"
                          itec-dialog-confirm-function-ok="ctrl.esborrar(obra,true)">
                    <i class="material-icons">delete_forever</i>
                  </button>

                  <tcq-bim-form-upload
                    ng-if="false && obra.importarbim && $root.permisos.SISQUILLO_CARREGAR_BIM.read"
                    is-new = "false"
                    obra-value = "obra"
                    dades-generals="ctrl.dadesGeneralsData"
                    save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"
                    max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"
                    max-size="10MB"
                    show-only-icon="true"
                    on-finish-function="ctrl.viewDadesCompartides"
                    server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_LOADER}}"
                    server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"
                    class="itec-float-right"
                    icon-left-class="icon-home-importar-bim"
                    title="{{'BIM_ARXIUS_ADD_TITLE' | translate}}"
                    show-toolbar-dialog="true">
                  </tcq-bim-form-upload>

                  <button ng-disabled="!obra.exportartcqi || !$root.permisos.SISQUILLO_EXPORTAR_TCQI.read"
                          class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
                          title="{{::'DEFAULT_EXPORTAR' | translate}}"
                          ng-click="ctrl.exportarObra(obra.id,true)">
                    <i class="icon-home-export-tcqi"></i>
                  </button>

                  <button ng-disabled="!obra.exportarfie || !$root.permisos.SISQUILLO_EXPORTAR_FIE.read"
                          id="fie"
                          class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
                          title="{{::'DEFAULT_EXPORTAR_FIE' | translate}}"
                          ng-click="ctrl.exportarObraFIE(obra.id,true)">
                     <i class="icon-home-export-fie"></i>
                  </button>

                  <button
                      ng-disabled="!obra.tornaracompartir || !$root.permisos.OBRES_COMPARTIR.read"
                      class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqihome--share-button"
                      title="{{::'DEFAULT_COMPARTIR_OBRA' | translate}}"
                      itec-dialog
                      itec-dialog-title="{{::'DEFAULT_COMPARTIR_OBRA' | translate}}"
                      itec-dialog-template-url="obres/obres-compartir.tpl.html"
                      itec-dialog-controller="ctrl"
                      itec-dialog-click-outside-to-close="false"
                      itec-dialog-function-init="ctrl.showDialogCompartirObra(obra.id,true)">
                    <!-- <i class="icon-gma-formacio-ozo-aire-1X"></i> -->
                    <i class="material-icons">share</i>
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
