(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.shared.breadcrumb
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.breadcrumb', [
      'itecGuicomponents.layout.wrapper.breadcrumb'
    ]);
    // .config(function(ItecBreadcrumbProvider) {
    //   ItecBreadcrumbProvider.compileWith(function(currentState, $interpolate) {
    //     var state = currentState.self;
    //     if(!state.breadcrumb) { return null; }
    //     if(typeof state.breadcrumb === 'string') {
    //       return {
    //         text: state.breadcrumb,
    //         stateName: state.name
    //       };
    //     } else {
    //       var breadcrumb = angular.copy(state.breadcrumb);

    //       if (breadcrumb.dynamicText) {
    //         breadcrumb.text=$interpolate(breadcrumb.dynamicText)(currentState.locals.globals);
    //       }

    //       if (breadcrumb.list){
    //         var list = JSON.parse($interpolate(breadcrumb.list)(currentState.locals.globals));
    //         var breadcrumbs = [];
    //         angular.forEach(list, function(obj){
    //           var aux = {};
    //           aux.stateName = breadcrumb.stateName;
    //           if (breadcrumb.stateParams){ //tractament per N paràmetres d'estat
    //             var result = '';
    //             angular.forEach(breadcrumb.stateParams, function(stateParam, index){
    //               if (index > 0){
    //                 result += ', ';
    //               }
    //               result = result + '\"' + stateParam.paramName + '\" : \"' + obj[stateParam.paramProperty] + '\"';
    //             });
    //             aux.stateName = aux.stateName + '({' + result + '})';
    //           } else if (breadcrumb.stateParam) {
    //             aux.stateName = aux.stateName + '({' + breadcrumb.stateParam.paramName + ' : ' + obj[breadcrumb.stateParam.paramProperty] + '})';
    //           }
    //           aux.class = breadcrumb.class;
    //           aux.text = breadcrumb.text;
    //           if (breadcrumb.textParams){
    //             var result = '';
    //             angular.forEach(breadcrumb.textParams, function(textParam, index){
    //               if (index > 0){
    //                 result += ', ';
    //               }
    //               result = result + '\"' + textParam.paramName + '\" : \"' + obj[textParam.paramProperty] + '\"';
    //             });
    //             aux.textParams = JSON.parse('{' + result + '}');
    //           } else if (breadcrumb.textParam) {
    //             aux.textParams = JSON.parse('{\"' + breadcrumb.textParam.paramName + '\" : \"' + obj[breadcrumb.textParam.paramProperty] + '\"}');  
    //           } else {
    //             aux.textParams = {};
    //           }
              
    //           breadcrumbs.push(aux);
    //         });
    //         return breadcrumbs;
    //       } else {
            
    //         if (breadcrumb.textParams){ //tractament per N paràmetres de text
    //           var result = '';
    //           angular.forEach(breadcrumb.textParams, function(textParam, index){
    //             if (index > 0){
    //               result += ', ';
    //             }
    //             result = result + '\"' + textParam.paramName + '\" : \"' + $interpolate(textParam.paramValue)(currentState.locals.globals) + '\"';
    //           });
    //           breadcrumb.textParams = JSON.parse('{' + result + '}');
    //         } else if (breadcrumb.textParam) { //tractament per un paràmetre de text
    //           breadcrumb.textParams = JSON.parse('{\"' + breadcrumb.textParam.paramName + '\" : \"' + $interpolate(breadcrumb.textParam.paramValue)(currentState.locals.globals) + '\"}');
    //         } else {
    //           breadcrumb.textParams = {};
    //         }

    //         if (breadcrumb.stateParams){ //tractament per N paràmetres d'estat
    //           var result = '';
    //           angular.forEach(breadcrumb.stateParams, function(stateParam, index){
    //             if (index > 0){
    //               result += ', ';
    //             }
    //             result = result + '\"' + stateParam.paramName + '\" : \"' + $interpolate(stateParam.paramValue)(currentState.locals.globals) + '\"';
    //           });
    //           breadcrumb.stateName = breadcrumb.stateName + '({' + result + '})';
    //         } else if (breadcrumb.stateParam) { //tractament per un paràmetre d'estat
    //           breadcrumb.stateName = breadcrumb.stateName + '({\"' + breadcrumb.stateParam.paramName + '\" : \"' + $interpolate(breadcrumb.stateParam.paramValue)(currentState.locals.globals) + '\"})';
    //         }
    //       }
    //       return breadcrumb;
    //     }
    //   });
    // });
}());
