(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.shared.locale
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.locale', [
      'ui.router'
    ]);
}());
