(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.api.sisquillo
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.sisquillo', [
    ]);
}());
