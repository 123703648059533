(function () {
  'use strict';

  /* @ngdoc object
   * @name shared.tables
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.tables', []);
}());
