(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.api.imatge
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.imatge', [
    ]);
}());
