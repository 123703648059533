(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name plantillaConductiuEspai.dades.controller:ObresDadesCtrl
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome.obres.dades')
    .controller('ObresDadesCtrl', ObresDadesCtrl);

  function ObresDadesCtrl(SisquilloFactory, TipologiaPreguntaConstants,
    HelperFactory, $stateParams, obraData, $state, $mdDialog, ItecTreeTracking,
    $rootScope, $window, $scope, Notification, $translate, dadesGeneralsData,
    tipologies, ImatgeFactory, ApiLayoutConstant, $cookies, TcqihomeConstant,SessionStorage) {

    var vm = this;

    vm.needRefresh = $stateParams.needRefresh || false;

    vm.back = back;
    vm.desarObra = desarObra;
    vm.refresh = refresh;
    vm.tipologies = tipologies.data;

    vm.ApiLayoutConstant = ApiLayoutConstant;
    vm.TcqihomeConstant = TcqihomeConstant;
    vm.TipologiaPreguntaConstants = TipologiaPreguntaConstants;

    vm.iconLeft = {};
    vm.iconLeft.class = 'material-icons';
    vm.iconLeft.content = 'file_upload';

    vm.backButton = {};
    vm.backButton.class = 'material-icons tcqi--button-icon-left';
    vm.backButton.content = 'arrow_back';
    vm.isNew = false;

    vm.deleteImage = deleteImage;

    init_app({name: 'tcqihome', version: ApiLayoutConstant.SERVER_VERSION_TCQIHOME},
        $cookies.get('authsecurity')).then( function(response) {

      vm.headers = {
        'x-api-version': ApiLayoutConstant.SERVER_VERSION_TCQIHOME,
        'authtcqihome': response.app.auth
      };
    });

    vm.errors = [];

    if (angular.isUndefined(obraData) || angular.isUndefined(obraData.data)) {
      HelperFactory.setParams($stateParams, {'idObra': undefined});
      vm.obra = {};
      vm.obra.monedes = [];
      vm.obra.monedes.push(dadesGeneralsData.data.monedaDefault);
      vm.isNew = true;
    } else {
      vm.obra = obraData.data.sisquillo;
      vm.obra.monedes = [];
      vm.obra.monedes.push(vm.obra.moneda);
      vm.isNew = false;
    }
    vm.maxUploadSize = dadesGeneralsData.data.maxUploadSizeImages;

    //Esta variable solo sirve la primera vez que se carga la pagina para que
    //se actualicen las preguntas de la tipología:
    var primeraVez = true;

    // vm.setTipologia = function setTipologia(){
    //   if(!angular.isUndefined(vm.obra.tipologia) && vm.obra.tipologia != null){
    //     angular.forEach (vm.tipologies, function (tipologia){
    //       if (tipologia.id === vm.obra.tipologia) {
    //         vm.obra.tipologia = tipologia;
    //       }
    //     });
    //   }
    // }


    //Actualizamos las preguntas de la tipología:
    if(!angular.isUndefined(vm.obra.id) && vm.obra.id != null &&
       !angular.isUndefined(vm.obra.tipologia.id) &&
       vm.obra.tipologia.id != null){
         actualizarPreguntasTipologia(vm.obra.id, vm.obra.tipologia.id)
    }


    vm.formulari = {
      nom: 'dadesGenerals',
      func: function() {
        return vm.desarObra();
      },
      grups: [
        {
          title:'DEFAULT_DADES_OBRA',
          camps: [
            {
              type: 'text',
              name: 'codi',
              label: 'DEFAULT_CODI',
              columns: 6,
              required: true,
              disabled: vm.obra.teSeguiment,
              maxLength: 50
            }, {
              type: 'text',
              name: 'descripcio',
              label: 'DEFAULT_TITOL_OBRA',
              columns: 6,
              required: true,
              disabled: vm.obra.teSeguiment,
              maxLength: 2000
            }, {
              type: 'chips',
              name: 'monedes',
              label: 'DEFAULT_MONEDA',
              fieldProperty: 'descripcion',
              textPlaceholder: $translate.instant('DEFAULT_MONEDA'),
              list: dadesGeneralsData.data.monedaList,
              required: true,
              readonly: vm.obra.teSeguiment,
              columns: 3,
              mdMaxChips: 2
            }, {
              type: 'multiTransclude',
              name: 'tipologia',
              label: 'DEFAULT_TIPOLOGIA',
              required: true,
              columns: 9,
              block: "tipologia-block"
            }, {
              type: 'select',
              simple: true,
              name: 'contractacio',
              label: 'DEFAULT_CONTRACTACIO',
              list: dadesGeneralsData.data.contractacioList,
              required: false,
              disabled: vm.obra.teSeguiment,
              columns: 6
            }, {
              type: 'select',
              simple: true,
              name: 'actuacio',
              label: 'DEFAULT_TIPUS_ACTUACIO',
              list: dadesGeneralsData.data.actuacioList,
              required: false,
              disabled: vm.obra.teSeguiment,
              columns: 6
            }, {
              type: 'textarea',
              name: 'notes',
              label: 'DEFAULT_NOTES',
              required: false,
              disabled: vm.obra.teSeguiment,
              columns: 12,
              maxLength: 2000
            },
            {
              type: 'datepicker',
              name: 'dataCreacio',
              label: 'DEFAULT_DATA_CREACION',
              required: false,
              disabled: true,
              show: (vm.obra.dataCreacio?true:false),
              columns: 2
            },
            {
            	type: 'numeric',
            	name: 'vidaUtil',
            	label: 'DEFAULT_VIDA_UTIL_ANYS',
            	decimalPlaces: 0,
            	columns:3,
            	required: false
            },
             {
              type: 'site',
              name: 'site_coordinates',
              latitud: 'site_latitud',
              longitud: 'site_longitud',
              label: 'DEFAULT_LOCALITZACIO',
              required: false,
              disabled: vm.obra.teSeguiment,
              columns: 12
            }
          ]
       }
     ]
    };

    function deleteImage() {
      ImatgeFactory.remove(vm.obra.imatge.id, vm.obra.id,SessionStorage.get('idEntitatCompartida')).then(function() {
        vm.obra.imatge = undefined;
      });
    }

    function back() {
      if (vm.needRefresh) {
        $state.go('^', $stateParams, {reload: true});
      } else {
        $state.go('^');
      }
    }

    function refresh() {
      $state.go('.', $stateParams, {reload: true});
    }

    function desarObra() {
      if (angular.isDefined(vm.obra.monedes[0])) {
        vm.obra.moneda = vm.obra.monedes[0];
      }
      // var obraAux = vm.obra;
      // obraAux.tipologia = obraAux.tipologia.id;
      $rootScope.$broadcast('$activateLoader');
      if (vm.isNew) {
        return SisquilloFactory.add(vm.obra).then(function(response) {
          $scope.$evalAsync(function() {
            Notification.success('OBRES_CREATE_SUCCESS');
            HelperFactory.setParams($stateParams, {'idObra': response.data});
            vm.isNew = false;
            //$state.go('.', $stateParams, {'reload':true});
            $rootScope.$broadcast('$deactivateLoader');

            vm.obra.id = response.data;
            vm.obra.dataCreacio = 'DEFAULT_NOW';

            back();
          });
        }).catch(function(error) {
          $rootScope.$broadcast('$deactivateLoader');
          vm.errors = Notification.processError(error);
        });

      } else {
        return SisquilloFactory.update(vm.obra.id, vm.obra,SessionStorage.get('idEntitatCompartida')).then(function() {
          Notification.success('OBRES_UPDATE_SUCCESS');
          $rootScope.$broadcast('$deactivateLoader');
        }).catch(function(error) {
          vm.errors = Notification.processError(error);
          $rootScope.$broadcast('$deactivateLoader');
        });
      }
    }

    $scope.$on ('tipologia-new-preguntes', function (evt, list) {
      // Copiem les respostes que ja existeixen
      angular.forEach (vm.obra.listPreguntes, function (preguntaExisting){
        angular.forEach (list, function (preguntaNova){
          if (preguntaExisting.idTipologiaPregunta === preguntaNova.idTipologiaPregunta) {
            preguntaNova.valorBoolea = preguntaExisting.valorBoolea;
            preguntaNova.valorNumeric = preguntaExisting.valorNumeric;
            preguntaNova.valorCadena = preguntaExisting.valorCadena;
          }
        });
      });
      vm.obra.listPreguntes = list;
    });


    vm.getDescPregunta = function(pregunta) {
      var result = pregunta.nom;
      if ((pregunta.ua !== null) && (pregunta.ua !== '')) {
        result += ' (' + pregunta.ua + ')';
      }
      return result;
    };

    function actualizarPreguntasTipologia(idObra, idTipologia){
      SisquilloFactory.getSisquilloTipologies(idObra, idTipologia,SessionStorage.get('idEntitatCompartida')).then(function (response) {
        $rootScope.$broadcast('tipologia-new-preguntes', response.data);
      }).catch(function (error) {
        vm.errors = Notification.processError(error);
      });
      $mdDialog.hide();
    }

    // Función que abre el dialog del árbol de tipologías
    // cargando el controlador TipologiaDialogController y la plantilla donde
    // esta definida la directiva del arbre-tipologies.
    vm.selectTipologia = function() {
      $mdDialog.show({
        locals: {obra: vm.obra, tipologies: vm.tipologies},
        controller: TipologiaDialogController,
        controllerAs: 'ctrl',
        templateUrl: 'obres/dades/tipologies.tpl.html',
        parent: angular.element(document.body),
        title:'DEFAULT_PREVENCIO_MINIMITZACIO',
        clickOutsideToClose:true,
        escapeToClose:true
      });
    }

    // Este es el controlador que se encarga del dialog que aparece al pulsar
    // sobre el botón de las tipologías.
    function TipologiaDialogController (obra, tipologies) {
      var ctrl = this;
      ctrl.obra = obra;
      ctrl.tipologies = tipologies;
      ctrl.tipologiaSelected = ctrl.obra.tipologia;
  
      ctrl.confirm = function(node) {
        ctrl.obra.tipologia = node;
        actualizarPreguntasTipologia(ctrl.obra.id, node.id);
      };

      ctrl.cancel = function () {
        $mdDialog.hide();
      };

    };
  }
}());
