(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name itecTcqihome.obres.controller:ObresCtrl
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome.compensacions')
    .controller('CompensacionsCtrl', CompensacionsCtrl);

  function CompensacionsCtrl(SisquilloFactory, HelperFactory, ConvertFactory,FileSaver, Notification, $cookies, $http,
      $rootScope, $scope, $state, $stateParams, $window, ApiLayoutConstant, ImatgeFactory, $mdDialog, $document,
      UploadFileConstants, allEntitats, PermisosFactory, entitatActual, $log, $timeout, dadesGeneralsData, compensacionsData,
      SessionStorage, TcqihomeConstant,ConfigService,DataFactory,obresCompartidesTotesEntitats,ServerRootPathService,$translate) {

    var vm = this;

    vm.maxObraSize = TcqihomeConstant.UPLOAD_OBRA_MAX_SIZE;
    vm.fields = [];
    vm.entitats = allEntitats;
    vm.errors = [];
    vm.showrols = false;
    vm.canLoad = true;

    vm.cercaObj = {
      page: 0,
      perPage: TcqihomeConstant.PAGINATE_LIST_PER_PAGE
    };
    vm.emisionsObresCompartides = null;
    vm.loadSisquillos = loadSisquillos;
    vm.viewDades = viewDades;
    vm.viewDadesCompartides=viewDadesCompartides;
    vm.esborrar = esborrar;
    vm.copiarObra=copiarObra;
    vm.exportarObra = exportarObra;
    vm.exportarObraFIE = exportarObraFIE;
    vm.addSisquillo = addSisquillo;
    vm.openObra = openObra;
    var loadImatgesObres = loadImatgesObres;
    vm.showObresByEntitat = showObresByEntitat;
    vm.showDialogCompartirObra = showDialogCompartirObra;
    vm.shareSisquillo = shareSisquillo;
    vm.esborrarSisquilloCompartit = esborrarSisquilloCompartit;
    vm.editarSisquilloCompartit = editarSisquilloCompartit;
    vm.back = back;

    vm.size = UploadFileConstants.SIZE_MINIMAL;
    vm.listMode = true;

    vm.addButton = {};
    vm.addButton.class = 'material-icons tcqi--button-icon-left';
    vm.addButton.content = 'add';

    vm.continueButton = {};
    vm.continueButton.class = 'material-icons tcqi--button-icon-right';
    vm.continueButton.content = 'keyboard_arrow_right';

    vm.backButton = {};
    vm.backButton.class = 'material-icons tcqi--button-icon-left';
    vm.backButton.content = 'arrow_back';

    vm.searchText = '';
    vm.sortType = '';
    vm.sortReverse  = false;
    vm.setSubObraExpand=setSubObraExpand;

    if (vm.entitats.length>0){
      vm.entitatSelected = vm.entitats[0];
    }
    else {
      vm.entitatSelected =entitatActual.data;
    }

    SessionStorage.save('idEntitat', entitatActual.data.id);
    SessionStorage.save('idEntitatCompartida','');

    vm.checkAgain = false;
    vm.checkDatosGenerales = false;
    vm.checkRemoveObra = false;
    vm.checkExportTcqi = false;
    vm.checkExportFie = false;
    vm.checkImportBim = false;
    vm.idSisquilloCompartit = undefined;
    vm.dadesGeneralsData = dadesGeneralsData.data;
    vm.compensacionsData = compensacionsData.data;
    vm.ApiLayoutConstant = ApiLayoutConstant;
    vm.hasaccessobraapp = hasaccessobraapp;
    vm.tesubsisquillos=tesubsisquillos;
    vm.titlemodul=titlemodul;

    vm.tcqiApps = {
      'security': {
        'id': ApiLayoutConstant.APP_ID_ADMINISTRACIO,
        'image': $rootScope.prefixItecGuicomponents +
                'images/layout/header/menu/directives/images/logos/security.png',
        'app': ApiLayoutConstant.APP_NOM_ADMINISTRACIO,
        'title': 'DEFAULT_TCQI_SECURITY',
        'descr': ''
      },
      'manteniment': {
        'id': ApiLayoutConstant.APP_ID_MANTENIMENT,
        'image': $rootScope.prefixItecGuicomponents +
                    'images/layout/header/menu/directives/images/logos/manteniment.png',
        'app': ApiLayoutConstant.APP_NOM_MANTENIMENT,
        'title': 'DEFAULT_TCQI_MANTENIMENT',
        'descr': ''
       },
       'areacollaborativa': {
         'id': ApiLayoutConstant.APP_ID_AREACOLLABORATIVA,
         'image': $rootScope.prefixItecGuicomponents +
                    'images/layout/header/menu/directives/images/logos/areaColaborativa.png',
         'app': ApiLayoutConstant.APP_NOM_AREACOLLABORATIVA,
         'title': 'DEFAULT_TCQI_AREA_COLABORATIVA',
         'descr': ''
       },
       'comparacioofertes': {
         'id': ApiLayoutConstant.APP_ID_COMPARACIOOFERTES,
         'image': $rootScope.prefixItecGuicomponents +
                    'images/layout/header/menu/directives/images/logos/comparacioOfertes.png',
         'app': ApiLayoutConstant.APP_NOM_COMPARACIOOFERTES,
         'title': 'DEFAULT_TCQI_COMPARACIO_OFERTES',
         'descr': ''
        },
        'estimaciocostos': {
          'id': ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS,
          'image': $rootScope.prefixItecGuicomponents +
                     'images/layout/header/menu/directives/images/logos/estimaciocostos.png',
          'app': ApiLayoutConstant.APP_NOM_ESTIMACIOCOSTOS,
          'title': 'DEFAULT_TCQI_ESTIMACIO_COSTOS',
          'descr': ''
         }
      };

    vm.obraCompartidaObj = {};

    vm.formulari = {
      nom: 'formulari',
      func: loadPermisosUserToCheck,
      camps: [{
          type: 'text',
          name: 'username',
          label: 'DEFAULT_USERNAME',
          columns: 12,
          required: true
        }
      ]};

    function loadSisquillos(deactivateLoader) {
      if (angular.isUndefined(vm.cercaObj.pages) || (vm.cercaObj.page + 1) <= vm.cercaObj.pages) {
        //$rootScope.$broadcast('$activateLoader');

        if (vm.cercaObj.page === 0) {
          vm.obres = [];
        }

        var params = {
          page: vm.cercaObj.page,
          perPage: vm.cercaObj.perPage
        };
        vm.canLoad = false;

        SisquilloFactory.getAllWithParams(params).then(function(response) {
          if (angular.isDefined(response.data.obres)) {
            response.data.obres.forEach(function(obj) {
              obj.isSubObraExpand=false;
              obj.visible=true;
              obj.isSubobra=false;
            for(var i = 0; i < vm.compensacionsData.length; i++) {
              var item =  vm.compensacionsData[i];
              if(item.idSisquillo == obj.id){
                 obj.emisions = item;
                  break;
              }
            }
              vm.obres.push(obj);
            });
          }
          vm.canLoad = true;
          vm.cercaObj.pages = response.data.pages;

          showIconAsObraCompartida(vm.obres,entitatActual.data.id);

          /*SisquilloFactory.getSisquillosCompartitsByEntitat(entitatActual.data.id).then(function(response) {*/
          //  showIconAsObraCompartida(vm.obres, response.data);
          /*}).finally(function() {*/
            /*if (deactivateLoader) {
              $rootScope.$broadcast('$deactivateLoader');
            }*/
          /*});*/
        });

        vm.cercaObj.page++;
      } else {
        vm.canLoad = false;
      }
    }

    function showIconAsObraCompartida(listObres, identitat) {
      if (obresCompartidesTotesEntitats != null) {
        if (obresCompartidesTotesEntitats[identitat]!=undefined){
            var listObresCompartides=obresCompartidesTotesEntitats[identitat];
            if (listObresCompartides != null && listObresCompartides.length > 0) {
              listObresCompartides.forEach(function(idObraCompartida) {
                if (listObres !== null) {
                  listObres.forEach(function(obra) {
                    if (obra.id === idObraCompartida) {
                      obra.isSisquilloCompartit = true;
                    }
                    else {
                      if (obra.subSisquillos!==null){
                        obra.subSisquillos.forEach(function(subobra) {
                          if (subobra.id === idObraCompartida) {
                            subobra.isSisquilloCompartit = true;
                          }
                        });
                      }
                    }
                  });
                }
              });
            }
          }
      }
      return listObres;
    }

    function showObresByEntitat(activateLoader) {
      if (activateLoader) {
        $rootScope.$broadcast('$activateLoader');
      } else {
        vm.showObras = false;
      }

      var params = {
          idEntitat: vm.entitatSelected.id,
          page: 0,
          perPage: vm.cercaObj.perPage
        };


      SisquilloFactory.getEntitatEmisions(params).then(
        function(response) {
          vm.obrescompartides = response.data;


       

          //SisquilloFactory.getSisquillosCompartitsByEntitat(vm.entitatSelected.id).then(function(response) {
            showIconAsObraCompartida(vm.obrescompartides, vm.entitatSelected.id);
          //}).finally(function() {
            $rootScope.$broadcast('$deactivateLoader');
            vm.showObras = true;
          //});
        }).catch(function(error){
          $log.error(error);
          vm.obrescompartides = undefined;

          $rootScope.$broadcast('$deactivateLoader');
          vm.showObras = true;
        });
    }


    function viewDades(id) {
      SessionStorage.save('idEntitatCompartida',undefined);

      if (angular.isDefined(id) && id !== null) {
        HelperFactory.setParams($stateParams, {'idObra': id});
        $state.go('obres.dades', $stateParams);
      }
    }


    function viewDadesCompartides(id,dadesgenerals)
    {
      if (id && dadesgenerals) {
        SessionStorage.save('idEntitatCompartida',vm.entitatSelected.id);
        HelperFactory.setParams($stateParams, {'idObra': id});
        $state.go('obres.dades', $stateParams);
      }
    }

    function esborrar(obra,compartida) {
      $rootScope.$broadcast('$activateLoader');
      var identcomp=undefined;
      if(compartida){
        identcomp=vm.entitatSelected.id;
      }
      SisquilloFactory.delete(obra.id,identcomp).then(function() {
        $scope.$evalAsync(function() {
          if (!obra.isSubobra)
          {
            HelperFactory.removeFromArray(vm.obres, obra.id);
          }
          else {
            esborrarsubobraarray(obra);
          }
        });
      }).catch(function(error){
        Notification.processError(error);
      }).finally(function() {
        $rootScope.$broadcast('$deactivateLoader');
      });
    }

    function exportarObra(id,compartida) {
      $rootScope.$broadcast('$activateLoader');
      var identcomp=undefined;
      if(compartida){
        identcomp=vm.entitatSelected.id;
      }
      SisquilloFactory.exportarObra(id,identcomp).then(function(response){
        var blob = new Blob([response.data], {type: response.headers('Content-Type')});
        FileSaver.saveAs(blob, response.headers('filename'));
      }).catch(function(error){
        Notification.processError(error);
      }).finally(function() {
        $rootScope.$broadcast('$deactivateLoader');
      });
    }


    function exportarObraFIE(id,compartida) {
      var identcomp=undefined;
      if(compartida){
        identcomp=vm.entitatSelected.id;
      }

      $mdDialog.show({
        controller: function DialogController($scope, $mdDialog, SisquilloFactory, Notification, $translate) {
          var vm = this;
          vm.export_plecs = true;
          vm.export_grafics = true;
          vm.export_amid = true;
          vm.export_codisoriginals=false;
          vm.errors = [];

          $scope.accept = function() {
          SisquilloFactory.exportarFie(id,vm.export_plecs,vm.export_grafics,vm.export_amid,vm.export_codisoriginals,identcomp).then(function(response){
            var blob = new Blob([response.data], {type: response.headers('Content-Type')});
            FileSaver.saveAs(blob, response.headers('filename'));
          }).catch(function(error){
            Notification.processError(error);
          }).finally(function() {
            $rootScope.$broadcast('$deactivateLoader');
          });
            $mdDialog.hide();
          };
          $scope.hide = function() {
            $mdDialog.cancel();
          };
          $scope.cancel = function() {
            $mdDialog.cancel();
          };
        },

        controllerAs: 'ctrl',
        templateUrl: 'obres/exportar-a-fie/exportar-a-fie-dialog.tpl.html',
        parent: angular.element($document.body),
        clickOutsideToClose:true
      });
    }



    function copiarObra(idObra) {
        $rootScope.$broadcast('$activateLoader');
        var url = ApiLayoutConstant.SERVER_PATH_LOADER + 'upload/' + idObra + '/copyObra';
        DataFactory.get(url).then(function(response) {
        	var dadesinicials=response.data;
            $rootScope.$broadcast('$deactivateLoader');

            $mdDialog.show({
                controller: function DialogController($scope, $mdDialog,  Notification, $translate) {
                  var vmc = this;
                  vmc.copiar_codi = dadesinicials.codi;
                  vmc.copiar_descripcio = dadesinicials.descripcio;
                  vmc.copiar_bolBanc = dadesinicials.bolBanc;
                  vmc.copiar_teBanc = dadesinicials.bolBanc;
                  vmc.copiar_bolPressupost = dadesinicials.bolPressupost;
                  vmc.copiar_tePressupost = dadesinicials.bolPressupost;
                  vmc.copiar_bolSeguiment = dadesinicials.bolSeguiment;
                  vmc.copiar_teSeguiment = dadesinicials.bolSeguiment;
                  vmc.copiar_bolPlaTreballs = dadesinicials.bolPlaTreballs;
                  vmc.copiar_tePlaTreballs = dadesinicials.bolPlaTreballs;
                  vmc.copiar_bolMA = dadesinicials.bolMA;
                  vmc.copiar_teMA = dadesinicials.bolMA;

                  vmc.copiar_bolPreusZero = dadesinicials.bolPreusZero;
                  vmc.copiar_bolFixarPreus = dadesinicials.bolFixarPreus;
                  vmc.copiar_bolDesFixarPreus = dadesinicials.bolDesFixarPreus;
                  vmc.copiar_bolSenseCT = dadesinicials.bolSenseCT;
                  vmc.copiar_bolSenseGrafics = dadesinicials.bolSenseGrafics;

                  vmc.errors = [];

                  vmc.fixarPreus = fixarPreus;
                  vmc.desFixarPreus = desFixarPreus;
                  vmc.copiarCertificacio=copiarCertificacio;

                  vmc.copiarMA=copiarMA;
                  vmc.preusZero=preusZero;

                  $scope.accept = function() {
                	vmc.errors = [];

                  	var form ={};
                  	form.sisIdCopia=idObra;
                    form.codi=  vmc.copiar_codi;
                    form.descripcio=  vmc.copiar_descripcio;
                    form.bolBanc=  vmc.copiar_bolBanc;
                    form.bolPressupost=vmc.copiar_bolPressupost;
                    form.bolSeguiment=  vmc.copiar_bolSeguiment;
                    form.bolPlaTreballs=  vmc.copiar_bolPlaTreballs;
                    form.bolMA=vmc.copiar_bolMA;
                    form.bolPreusZero=  vmc.copiar_bolPreusZero;
                    form.bolFixarPreus=  vmc.copiar_bolFixarPreus;
                    form.bolDesFixarPreus=  vmc.copiar_bolDesFixarPreus;
                    form.bolSenseCT=  vmc.copiar_bolSenseCT;
                    form.bolSenseGrafics=  vmc.copiar_bolSenseGrafics;


                    $rootScope.$broadcast('$activateLoader');
                    var url = ApiLayoutConstant.SERVER_PATH_LOADER + 'upload/' + idObra + '/copyObra';
                    DataFactory.add(url,form).then(function(response) {
                        Notification.success("DEFAULT_ACCIO_FINALITZADA_EXIT");
                    	$mdDialog.hide();
                        vm.cercaObj = {
                        	      page: 0,
                        	      perPage: TcqihomeConstant.PAGINATE_LIST_PER_PAGE
                        	    };
                        loadSisquillos(false);
                        loadImatgesObres();
                        showObresByEntitat(false);


                    }).catch(function(error){
                    	error.validations.forEach(function(validation){
                    		vmc.errors.push(validation);
                    	});
                        Notification.error("GENERIC_ERROR_DEFAULT");
                      }).finally(function() {
                        $rootScope.$broadcast('$deactivateLoader');
                      });
                  };
                  $scope.hide = function() {
                    $mdDialog.cancel();
                  };
                  $scope.cancel = function() {
                    $mdDialog.cancel();
                  };


                  // Funcions internes
                  function fixarPreus(){
                      if(vmc.copiar_bolFixarPreus===true){
                    	  vmc.copiar_bolDesFixarPreus = false;
                      }
                    }

                    function desFixarPreus(){
                        if(vmc.copiar_bolDesFixarPreus===true){
                      	  vmc.copiar_bolFixarPreus = false;
                        }

                        if(vmc.copiar_teSeguiment ===true){
	                        if(vmc.copiar_bolSeguiment ===true){
		                        	vmc.copiar_bolDesFixarPreus = false;
                            }
	                        else{
	                        	vmc.copiar_bolDesFixarPreus = true;
	                        }
                        }
                      }

                    function copiarCertificacio(){
                        if(vmc.copiar_bolSeguiment ===true){
                        	vmc.copiar_bolPreusZero = false;
                            if(vmc.copiar_teSeguiment ===true){
	                        	vmc.copiar_bolDesFixarPreus = false;
                            }
                        }
                        if(vmc.copiar_bolSeguiment ===false && vmc.copiar_teSeguiment ===true ){
                        	vmc.copiar_bolDesFixarPreus = true;
                        }

                      }

                    function copiarMA(){
                        if(vmc.copiar_bolMA ===true){
                            vmc.copiar_bolBanc = true;
                            vmc.copiar_bolPressupost = true;
                        }

                      }



                    function preusZero(){
                        if(vmc.copiar_bolSeguiment ===true){
                        	vmc.copiar_bolPreusZero = false;
                        }
                      }

                },

                controllerAs: 'ctrl',
                templateUrl: 'obres/copiar-obra/copiar-obra-dialog.tpl.html',
                parent: angular.element($document.body),
                clickOutsideToClose:true
              });


        }).catch(function(error){
            $rootScope.$broadcast('$deactivateLoader');
          });

      }


    function openObra(id, app, hasAccess,compartida) {
      var domini=ServerRootPathService.getServerRootPath();
      if (compartida){
          var parament='?entitat=' + vm.entitatSelected.id;
      }
      else {
          var parament='?entitat=' + ConfigService.getEntitat();
      }
      if (hasAccess) {
        if (app === ApiLayoutConstant.APP_NOM_PRESSUPOST) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_PRESSUPOST +
            '/pressupost/#/' + id + '/obra/estructura' + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_QUALITAT) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_QUALITAT +
            '/qualitat/#/' + 'obra/' + id + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_MEDIAMBIENT) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_MEDIAMBIENT +
            '/mediambient/#/' + 'obres/' + id + '/compensacions/climatetrade' + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_SEGURETATISALUT) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_SEGURETATISALUT +
            '/seguretatisalut/#/' + 'obra/' + id + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_PLANIFICACIO) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_PLANIFICACIO +
             '/planificacio/#/' + 'obres/' + id + '/tasques/gantt' + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_SEGUIMENTECONOMIC) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_SEGUIMENTECONOMIC +
            '/seguimenteconomic/#/' + id + '/obra/estructura' + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_MANTENIMENT) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_MANTENIMENT +
            '/manteniment');
        } else if (app === ApiLayoutConstant.APP_NOM_AREACOLLABORATIVA) {
          $window.open(domini + 'areacolaborativa');
        } else if (app === ApiLayoutConstant.APP_NOM_COMPARACIOOFERTES) {
          $window.open(domini + 'comparadorofertes');
        } else if (app === ApiLayoutConstant.APP_NOM_ADMINISTRACIO) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_ADMINISTRACIO +
            '/security');
        } else if (app === ApiLayoutConstant.APP_NOM_ESTIMACIOCOSTOS) {
          if (angular.isUndefined(id)) {
            $window.open(domini + ApiLayoutConstant.SERVER_VERSION_ESTIMACIOCOSTOS +
              '/estimaciocostos' + parament);
          } else {
            $window.open(domini + ApiLayoutConstant.SERVER_VERSION_ESTIMACIOCOSTOS +
              '/estimaciocostos/#/' + id + '/assignacio/estructura' + parament);
          }
        } else if (app === ApiLayoutConstant.APP_NOM_CONTROLCOSTOS) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_CONTROLCOSTOS +
            '/controlcostos/#/obra/' + id + '/arxiu' + parament);
        } /*else if (app === ApiLayoutConstant.APP_NOM_BANC) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_BANC +
            '/banctcq/#/' + id + '/obra/estructura'  + parament);
        }*/
      }
    }


    function showDialogCompartirObra(idObra,compartida) {
      vm.showCompartirModulos = false;
      vm.sisquillosCompartits = {};
      var entobra=undefined;
      if (compartida){
          entobra=vm.entitatSelected.id;
      }
      else {
          entobra=ConfigService.getEntitat();
      }
      // Se cargan los usuarios compartidos de esta obra.
      $rootScope.$broadcast('$activateLoader');
      SisquilloFactory.getSisquillosCompartits(idObra,entobra).then(function(response) {
        vm.sisquillosCompartits = response.data;
      }).finally(function() {
        $rootScope.$broadcast('$deactivateLoader');
      });

      vm.obraSelected = idObra;
      vm.obraCompartidaObj.username = '';
      vm.obraCompartidaObj.idEntitat=entobra;
      vm.obraCompartidaObj.compartida=compartida;
    }


    function loadPermisosUserToCheck() {
      $rootScope.$broadcast('$activateLoader');

      vm.checkAgain = false;
      vm.checkDatosGenerales = false;
      vm.checkRemoveObra = false;
      vm.checkExportTcqi = false;
      vm.checkExportFie = false;
      vm.checkImportBim = false;

      //vm.obraCompartidaObj.idEntitat = vm.idEntitat;
      vm.obraCompartidaObj.idObra = vm.obraSelected;

      vm.idSisquilloCompartit = undefined;

      SisquilloFactory.getUsernamePermisos(vm.obraCompartidaObj).then(function(response) {
        vm.permisosModulsUsername = response.data;
        vm.showCompartirModulos = true;
      }).catch(function(error) {
        vm.errors = Notification.processError(error);
        vm.showCompartirModulos = false;
        $timeout(function() {
          vm.errors = [];
          vm.obraCompartidaObj.username = '';
        }, 5000);
      }).finally(function() {
        $rootScope.$broadcast('$deactivateLoader');
      });
    }


    function esborrarSisquilloCompartit(idSisquilloCompartit, index,identitatcompartida) {
      $rootScope.$broadcast('$activateLoader');

      SisquilloFactory.deleteSisquilloCompartit(idSisquilloCompartit).then(function() {
        // Se cargan los usuarios compartidos de esta obra.
        SisquilloFactory.getSisquillosCompartits(vm.obraSelected,vm.obraCompartidaObj.idEntitat).then(function(response) {
          vm.sisquillosCompartits = response.data;
        }).finally(function() {
          $rootScope.$broadcast('$deactivateLoader');
          if (vm.sisquillosCompartits.listsisquillocompartit.length === 0) {
            hideIconAsObraCompartidaByIdObra(vm.obraSelected,vm.obraCompartidaObj.idEntitat);
          }
        });
      });
    }

    function deleteFromListCompartida(idObraACompartir,identitat) {
      var index=undefined;
      if (obresCompartidesTotesEntitats != null) {
        if (obresCompartidesTotesEntitats[identitat]!=undefined){
            var listObresCompartides=obresCompartidesTotesEntitats[identitat];
            if (listObresCompartides != null && listObresCompartides.length > 0) {
              for(var i = 0; i < listObresCompartides.length; i++) {
                if (listObresCompartides[i] === idObraACompartir) {
                  index=i;
                  break;
                }
              }
              if (index!=undefined)
              {
                obresCompartidesTotesEntitats[identitat].splice(index,1);
              }
            }
          }
      }
    }




    function hideIconAsObraCompartidaByIdObra(idObraACompartir,identitat) {
      var found = false;
      deleteFromListCompartida(idObraACompartir,identitat);
      if (vm.obrescompartides != null && vm.obrescompartides.length > 0) {
        vm.obrescompartides.forEach(function(obra) {
          if (idObraACompartir === obra.id) {
            obra.isSisquilloCompartit = false;
            found = true;
          }
        });
      }

      if (!found) {
        if (vm.obres != null && vm.obres.length > 0) {
          vm.obres.forEach(function(obra) {
            if (idObraACompartir === obra.id) {
              obra.isSisquilloCompartit = false;
            }
            else {
              obra.subSisquillos.forEach(function(subobra){
                if (idObraACompartir === subobra.id) {
                  subobra.isSisquilloCompartit=false;
                }
              });
            }
          });
        }
      }
      return listObres;
    }


    function addSisquillo() {
      SessionStorage.save('idEntitatCompartida',undefined);
      $window.scrollTo(0, 0);
      HelperFactory.setParams($stateParams, {'isNew': true, 'idObra': null});
      $state.go('obres.dades', $stateParams);
    }


    function loadImatgesObres() {
      angular.forEach(vm.obres, function(obra, index) {
        if (obra.imatge) {
          ImatgeFactory.download(obra.imatge.id, vm.size, obra.id).then(function(response2) {
            vm.obres[index].imatge.data = response2.data;
          });
        }
      });
    }


    function back($form) {
      vm.showCompartirModulos = false;
      vm.obraCompartidaObj.username = '';
    }


    function shareSisquillo() {
      var form = {};
      form.idobra = vm.obraSelected;
      form.username = vm.obraCompartidaObj.username;
      form.tornaracompartir = vm.checkAgain;
      form.dadesgenerals = vm.checkDatosGenerales;
      form.eliminarobra = vm.checkRemoveObra;
      form.exportartcqi = vm.checkExportTcqi;
      form.exportarfie = vm.checkExportFie;
      form.importarbim = vm.checkImportBim;

      form.listaplicaciorol = [];
      //form.identitat=vm.entitatSelected.id;
      form.identitat=vm.obraCompartidaObj.idEntitat;

      angular.forEach(vm.permisosModulsUsername, function(permiso, index) {
        if (permiso.id) {
          if (!angular.isUndefined(permiso.rol) && vm.showrols) {
            form.listaplicaciorol.push({'idaplicacio': permiso.idaplicacio, 'idrol': permiso.rol.id});
          } else {
            form.listaplicaciorol.push({'idaplicacio': permiso.idaplicacio, 'idrol': undefined});
          }
        }
      });

      var hasError = false;
      if (angular.isDefined(form.listaplicaciorol) && form.listaplicaciorol.length > 0) {
        if (angular.isUndefined(vm.idSisquilloCompartit)) {
          SisquilloFactory.addSisquilloCompartit(form).then(function() {
            showDialogCompartirObra(vm.obraSelected,vm.obraCompartidaObj.compartida);
            Notification.success('OBRES_SHARED_CREATE_SUCCESS');
          }).catch(function(error) {
            hasError = true;
            Notification.processError(error);
          }).finally(function(){
            if (!hasError) {
              showIconAsObraCompartidaByIdObra(vm.obraSelected,vm.obraCompartidaObj.idEntitat);
            }
          });
        } else {
          form.id=vm.idSisquilloCompartit;

          SisquilloFactory.updateSisquilloCompartit(form).then(function() {
            showDialogCompartirObra(vm.obraSelected,vm.obraCompartidaObj.compartida);
            Notification.success('OBRES_SHARED_EDIT_SUCCESS');
            vm.idSisquilloCompartit=undefined;
          }).catch(function(error) {
            hasError = true;
            Notification.processError(error);
          }).finally(function(){
            if (!hasError) {
              showIconAsObraCompartidaByIdObra(vm.obraSelected,vm.obraCompartidaObj.idEntitat);
            }
          });
        }
      } else {
        Notification.error('obra.compartida.error.modules.not_checked');
      }
    }

    function actualitzallistaobrescompartides(idObraACompartir,identitat)
    {
      if (obresCompartidesTotesEntitats[identitat]!=undefined){
        obresCompartidesTotesEntitats[identitat].push(idObraACompartir);
      }
      else {
        obresCompartidesTotesEntitats[identitat]=[idObraACompartir];
      }
    }
    function showIconAsObraCompartidaByIdObra(idObraACompartir,identitat) {
      var found = false;

      if (vm.obrescompartides != null && vm.obrescompartides.length > 0) {
        vm.obrescompartides.forEach(function(obra) {
          if (idObraACompartir === obra.id) {
            if (!obra.isSisquilloCompartit) {
              actualitzallistaobrescompartides(idObraACompartir,identitat);
            }
            obra.isSisquilloCompartit = true;
            found = true;
          }
        });
      }

      if (!found) {
        if (vm.obres != null && vm.obres.length > 0) {
          vm.obres.forEach(function(obra) {
            if (idObraACompartir === obra.id) {
              if (!obra.isSisquilloCompartit) {
                actualitzallistaobrescompartides(idObraACompartir,identitat);
              }
              obra.isSisquilloCompartit = true;
            }
            else{
              obra.subSisquillos.forEach(function(subobra){
                if (idObraACompartir === subobra.id) {
                  if(!subobra.isSisquilloCompartit) {
                    actualitzallistaobrescompartides(idObraACompartir,identitat);
                  }
                  subobra.isSisquilloCompartit = true;
                }
              });
            }
          });
        }
      }
      return listObres;
    }

    function titlemodul(temodul,tepermis)
    {
      var title="";
      if (tepermis){
        title=$translate.instant('TE_PERMIS');
      }
      else {
        title=$translate.instant('NO_TE_PERMIS');
      }
      if (temodul)
      {
        title=title + $translate.instant('OBRA_TE_INFORMACIO');
      }
      else {
        title=title +$translate.instant('OBRA_NO_TE_INFORMACIO');
      }
      return title;
    }

    function hasaccessobraapp(obra, idapp) {
      var appcompartida = false;
      if (obra.listIdsAplicacionsCompartides!==null){
        for(var i = 0; i < obra.listIdsAplicacionsCompartides.length; i++) {
          if (obra.listIdsAplicacionsCompartides[i].toString() === idapp) {
            appcompartida = true;
            break;
          }
        }
      }
      return appcompartida ;
    }


    function editarSisquilloCompartit(idSisquilloCompartit,username) {
      $rootScope.$broadcast('$activateLoader');

      SisquilloFactory.getSisquilloCompartit(idSisquilloCompartit).then(function(response) {
          vm.showCompartirModulos = true;
          vm.permisosModulsUsername = response.data.listaccesstcqi;
          var listappscompartides = response.data.listaplicaciorol;

          angular.forEach(vm.permisosModulsUsername, function(permiso, index) {
            var compartida = false;

            for (var i=0;i<listappscompartides.length;i++ ) {
              if (listappscompartides[i].idaplicacio === permiso.idaplicacio) {
                compartida=true;
                break;
              }
            }
            permiso.id = compartida;

            if (permiso.id && listappscompartides[i].idrol !== null) {
              for(var j = 0; j < permiso.listrols.length; j++) {
                if (permiso.listrols[j].id === listappscompartides[i].idrol) {
                  vm.showrols = true;
                  permiso.rol = permiso.listrols[j];
                  break;
                }
              }
            }
          });

          vm.checkAgain = response.data.tornaracompartir;
          vm.checkDatosGenerales = response.data.dadesgenerals;
          vm.checkRemoveObra = response.data.eliminarobra;
          vm.checkExportTcqi = response.data.exportartcqi;
          vm.checkExportFie = response.data.exportarfie;
          vm.checkImportBim = response.data.importarbim;


          vm.idSisquilloCompartit = idSisquilloCompartit;
          vm.obraCompartidaObj.username = username;
          vm.obraCompartidaObj.idEntitat=response.data.identitat;
      }).catch(function(error) {
        vm.errors = Notification.processError(error);
        vm.showCompartirModulos = false;
        $timeout(function() {
          vm.errors = [];
        }, 5000);
      }).finally(function() {
        $rootScope.$broadcast('$deactivateLoader');
      });
    }


    // Se recalcula la altura del "scroll" (elemento con clase CSS "tcqi--with-scroll") de la capa de obras
    // cuando se cambia de identidad.
    $scope.$on('ngRepeatFinished', function(ngRepeatFinishedEvent) {
      $rootScope.$broadcast('tcqiAppLoaded');

      if (angular.isUndefined(vm.obrescompartides) || vm.obrescompartides === null ||
          (vm.obrescompartides !== null && vm.obrescompartides.length === 0)) {
        var userSisquilloList = angular.element('.tcqi--table-tbody');
        var htmlUserSisquilloList = userSisquilloList[0];
        var top = htmlUserSisquilloList.getBoundingClientRect().top;
        var windowHeight = $window.innerHeight;

        $('#' + htmlUserSisquilloList.id).css('cssText', 'height:' + (windowHeight - top) + 'px !important');
      }
    });


  /*  if (angular.isUndefined($rootScope.acces) || $rootScope.acces === null) {
      PermisosFactory.loadWithURL(ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'accesos').then(
        function(response) {
          $rootScope.acces = response.data.acces;
        }
      ).catch(function(){});
    }
*/



    function tesubsisquillos(obra) {
      return obra.subSisquillos.length>0 ;
    }

    function setSubObraExpand(expand,obra) {
        obra.isSubObraExpand=expand;
        obra.subSisquillos.forEach(function(obj) {
          obj.visible=expand;
          obj.isSubobra=true;
        });
    }


    loadSisquillos(false);
    loadImatgesObres();
    showObresByEntitat(false);

    function esborrarsubobraarray(subobraesborrar){
      vm.obres.forEach(function(obra) {
        if (obra.id === subobraesborrar.idpare) {
          if (obra.subSisquillos!==null){
             obra.subSisquillos.forEach(function(subobra,index) {
              if (subobra.id === subobraesborrar.id) {
                obra.subSisquillos.splice(index,1);
              }
            });
          }
        }
      });
    }

  }
}());
