(function () {
  'use strict';

  /* @ngdoc object
   * @name api.locale
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.locale', [
      'itecApi'
    ]);
}());
