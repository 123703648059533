<div class="mdl-grid mdl-grid--no-spacing">
  <div class="mdl-cell mdl-cell--12-col">
    <form name="formulari" novalidate>
      <div id="itec-left-filter-pdf" class="mdl-grid mdl-grid--no-spacing">
        <div id="buscador" ng-repeat="group in fields" ng-if="ctrl.expandedSearch" class="mdl-cell mdl-cell--12-col group-div">
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--{{group.columns ? group.columns : 12}}-col">
              <div class="mdl-grid" ng-hide="group.collapsed">

                <div ng-repeat="field in group.camps" class="mdl-cell mdl-cell--12-col">
                  <div ng-include="'common-controls/directives/itec-form-fields.tpl.html'"></div>
                  <div ng-if="field.type === 'multiTransclude'" ng-transclude transclude-from="{{field.block}}" name="{{field.name}}"></div> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div id="itec-content-filter-pdf" class="mdl-cell mdl-cell--12-col">
    <form id="pdfButtons">
      <itec-pdf-buttons
        pre-function="preFunction()"
        go-pdf-url="ctrl.goPdfUrl()"
        doc-url="ctrl.pdfUrl"
        download-doc="ctrl.downloadDoc()"
        download-xls="ctrl.downloadXls()"
        doc-button="docButton"
        xls-button="xlsButton"
        go-transclude="ctrl.goTransclude()"
        transclude-button="ctrl.havingTranscludeOp"
        transclude-text="{{transcludeButtonText}}"
        >
      </itec-pdf-buttons>
    </form>
    <div ng-show="ctrl.showPdfLayout">
      <iframe type="text/html" ng-src="{{trustSrc(ctrl.pdfUrl)}}" style="height: 900px;" class="mdl-cell mdl-cell--12-col"></iframe>
    </div>
  </div>
</div>