(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name itecTcqihome.shared.headerSubmenu.controller:HeaderSubmenuCtrl
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.headerSubmenu')
    .controller('HeaderSubmenuCtrl', HeaderSubmenuCtrl);

  function HeaderSubmenuCtrl(
                optionsHeaderSubmenuData,
                AppHeaderSubmenuFactory, 
                PermisosFactory,
                $rootScope, $scope, $state, $stateParams) {
    var vm = this;   
    
    if (!angular.isUndefined(optionsHeaderSubmenuData.addPermit) && optionsHeaderSubmenuData.addPermit !== null && (angular.isUndefined($stateParams.submenuInfo.left.addButton) || $stateParams.submenuInfo.left.addButton)) {
      $stateParams.submenuInfo.left.addButton = PermisosFactory.isVisible(optionsHeaderSubmenuData.addPermit, 'add');
    }
    
    $scope.$on(optionsHeaderSubmenuData.name + '.showOptionSubmenu',function(event, data){
      if (angular.isUndefined(data.onlyFor)) {
        if (data.show){
           angular.forEach(optionsHeaderSubmenuData.options, function(key){
              key.show = PermisosFactory.isVisible(key.permit, 'read');
            });
        }else{
          angular.forEach(optionsHeaderSubmenuData.options, function(key){
            key.show = false;
          });
        }
      }
      else {
        //primer tot a false
        angular.forEach(optionsHeaderSubmenuData.options, function(key){
            key.show = false;
        });
        //a true tots els menus que no tenen camp onlyFor o el tenen i coincideix amb el data.onlyFor
        angular.forEach(optionsHeaderSubmenuData.options, function(key){
          if(!angular.isUndefined(key.onlyFor)) {
            if (data.onlyFor === key.onlyFor) {
              key.show = PermisosFactory.isVisible(key.permit, 'read');
            }
          }
          else {
            key.show = PermisosFactory.isVisible(key.permit, 'read');
          }
        });
      }


    });
    
    AppHeaderSubmenuFactory.resize($state, $stateParams, optionsHeaderSubmenuData.name);

    AppHeaderSubmenuFactory.createAttributes(vm, optionsHeaderSubmenuData.options, $stateParams, false);


  }
}());