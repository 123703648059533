(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.shared.locale.factory:LocaleInterceptorFactory
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.locale')
    .factory('LocaleInterceptorFactory', LocaleInterceptorFactory);

  function LocaleInterceptorFactory($cookies, LocalStorage, LocaleService) {
    var requestInterceptor = {
      response: function(response) {
        var localeCookie = $cookies.get('org.springframework.web.servlet.i18n.CookieLocaleResolver.LOCALE');
        if (!angular.isUndefined(localeCookie)) {
          var locale = LocalStorage.get('locale');
          if (locale !== localeCookie) {
            LocalStorage.save('locale', localeCookie);
            LocaleService.setLocale(localeCookie);
          }  
        }
        
        return response;
      }
    };
    return requestInterceptor;
  }

}());
