(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name itecTcqihome.shared.pdfViewer.directive:mntPdfViewer
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="itecTcqihome.shared.pdfViewer">
       <file name="index.html">
        <mnt-pdf-viewer></mnt-pdf-viewer>
       </file>
     </example>
   *
   */
  angular
    .module('itecTcqihome.shared.pdfViewer')
    .directive('mntPdfViewer', mntPdfViewer);

  function mntPdfViewer() {
    return {
      restrict: 'EA',
      scope: {
        fields                : '=',
        ngModel               : '=',
        baseUrl               : '=',
        paramsUrl             : '=',
        errors                : '=',
        title                 : '@?',
        docButton             : '=?',
        xlsButton             : '=?',
        transcludeButton      : '=?',
        transcludeButtonAction: '&?',
        transcludeButtonText  : '@?',
        preFunction           : '&?',
        titleBuscador         : '@'
      },
      templateUrl: 'shared/pdf-viewer/mnt-pdf-viewer-directive.tpl.html',
      transclude: true,
      controllerAs: 'ctrl',
      controller: function ($scope, $window, $sce) {
        var vm = this;
        vm.name = 'mntPdfViewer';
        vm.pdfUrl = '';

        vm.showPdfLayout        = false;
        vm.showTranscludeLayout = false;
        vm.havingTranscludeOp   = $scope.transcludeButton === true;
        vm.goPdfUrl = goPdfUrl;
        vm.downloadDoc = downloadDoc;
        vm.downloadXls = downloadXls;
        vm.expandedSearch = true;
        
        if (vm.havingTranscludeOp){
          vm.goTransclude = goTransclude;
        }

        $scope.$watch('errors[\'\']', function (newValue, oldValue){
          if (!angular.isUndefined(newValue) && newValue.length > 0) {
            $window.scrollTo(0, 0);
          }
        });

        $scope.trustSrc = function(src) {
         return $sce.trustAsResourceUrl(src);
        }

        function goPdfUrl(){
          vm.showPdfLayout        = true;
          vm.showTranscludeLayout = false;
  
          var params = '?format=pdf' + $scope.paramsUrl;
          vm.pdfUrl = $scope.baseUrl + params;
        }
        
        function downloadDoc(){
          var params = '?format=doc' + $scope.paramsUrl;
          vm.pdfUrl = $scope.baseUrl + params;
        }

        function downloadXls(){
          var params = '?format=xls' + $scope.paramsUrl;
          vm.pdfUrl = $scope.baseUrl + params;
        }

        function goTransclude(){
          vm.showPdfLayout        = false;
          vm.showTranscludeLayout = true;
          $scope.transcludeButtonAction();
        }
        //console.log($scope);

      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

        scope.showError = false;

        var iframe = element.find('iframe');
        iframe.bind('load', function (event) {
          if ( iframe[0].innerHTML ) {
            scope.$apply(function(){
              var status = null;
              scope.messageError = '';

              var head = iframe[0].contentDocument.head;
              if (head){
                var metaStatus = head.querySelector('meta[name=status]');
                if (metaStatus !== null) {
                    status = metaStatus.content;
                }
              }
              
              if ( status !== null && status >= 300 ) {
                var metaMessage = head.querySelector('meta[name=message]');
                if (metaMessage !== null) {
                    scope.messageError = metaMessage.content;
                }
                var metaValidations = head.querySelector('meta[name=validations]');
                if (metaValidations !== null) {
                    scope.errors = Notification.processError({validations: JSON.parse(metaValidations.content.replace(new RegExp('\'', 'g'),'"'))});
                }
                scope.showError = true;
              } else {
                scope.showError = false;
                scope.errors = [];
              }
            });
          } else {
            // didn't load
          }
          
        });

        if (angular.isUndefined(scope.submitButtonText)){
          scope.submitButtonText = "DEFAULT_SUBMIT_TEXT";
        }

        scope.$emit('itecColumnFilterDirectiveReady');
      }
    };
  }
}());
