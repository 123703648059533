<div  class="contractes-content">
  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell mdl-cell--12-col">
      <md-card>
        <md-card-title>{{title | translate}}</md-card-title>
        <md-card-content>
          <table class="itec-table">
            <thead>
              <tr>
                <th>{{ 'DEFAULT_TIPUS_MANTENIMENT'  | translate }}</th>
                <th>{{ 'DEFAULT_DESCRIPCIO'         | translate }}</th>
                <th>{{ 'DEFAULT_ACCIONS'            | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr ng-repeat="row in notificationsDades" class="{{row.llegit?'':'bold'}}">
                <td>
                  <span ng-if="!angular.isUndefined(row.tipusMant)">
                    {{row.tipusMant}}<br/>
                  </span>
                  {{row.dmy}}
                </td>
              <td>
                <pre>
                  {{row.descripcio}}
                </pre>
              </td>
              <td>
                <button ng-if="!row.llegit" class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored" ng-click="notificationsFunctions[0](row.id)">
                  <i class="material-icons">markunread</i>
                  <!--_OK_READ_ACTION_-->
                </button>
                <button ng-if="row.llegit" ng-click="notificationsFunctions[1](row.id)" class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored">
                <i class="material-icons">delete</i>
                <!--_OK_DELETE_MESSAGE_ACTION_-->
                </button>
                <a  ng-if="row.fileName !== null" 
                    class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored" 
                    href="{{notificationsFunctions[2](row.id,row.fileName)}}" 
                    target="_self">
                  <i class="material-icons">file_download</i>
                  <!--_OK_DOWNLOAD_MESSAGE_ANNEX_-->
                </a>
              </td>
              </tr>
            </tbody>
          </table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</div>
